<template src="./recipient.html">
</template>
<style scoped src="./recipient.css">

</style>

<script>

import accountConstants from '@/constants/AccountConstants.vue'
import { mapActions, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import AccountService from '@/services/AccountService'
import applicationConstants from '@/constants/AppConstants.vue'
import getRecipient  from '../../modal/getRecipient/recipient.vue'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'

export default {
    data () {
        return {
            useremail : '',            
            countryList : accountConstants.countryList,
            columns : ['Id','Name','PhoneNumber','RemittanceGateway','RemittanceCurrency','Action'],
            options : {
                headings : {
                    Id : 'Recipient Id',
                    PhoneNumber : 'Phone No',
                    RemittanceGateway : 'Remittance Gateway',
                    RemittanceCurrency : 'Currency'
                }
            },
            loaderVisible : false,
            RecipientItems : [],
            remittenceOptionList : [],
            gatewayList: [],
            opContryList : accountConstants.countryList,
            countryCodeList : [],
            opCountry: '',
            remittenceOptionId : '',
            remittanceCurrency: '',
            gatewayId : '',
            recipientFeides: [],
            action: '',
            showContainer : true,
            errorMessages: applicationConstants.formsMessages,
            recipientId : '',
            showSubmitButton : false,
            Utils : Utils,
            editRemitInfoOnAdd: false,
            ifsc: null, //used for checking,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            }
        }
    },
    methods : {
        ...mapActions(['fetchRecipientDetails','fetchRemittanceOption','reserveUseremail']),
        async opCountryChangeEvent (event) {
            this.remittenceOptionList = []   
            this.gatewayList = []
            this.countryCodeList = []
            if(event.length > 0) {
                this.countryCodeList = accountConstants.countryList.find(s => s.Text == event)
                if (this.getRemittanceOptions2 === null || this.getRemittanceOptions2 == undefined) {
                    await this.fetchRemittanceOption()
                }   
                let remittenceOption = this.getRemittanceOptions2.filter( s=> s.Country === this.countryCodeList.Code)
                for (const [key, value] of Object.entries([...new Set(remittenceOption.map(item => item.Category))])) {
                    this.remittenceOptionList.push({
                        Text: value,
                        Value : value
                    })  
                }
            }
            if (this.remittenceOptionList.length === 1 ) {
                this.remittenceOptionId = this.remittenceOptionList[0].Text 
                this.remittenceOptionChangeEvent(this.remittenceOptionList[0].Text)
            } else if(this.remittenceOptionList.length === 0) {
                this.remittenceOptionId = ''
            } 
        },
        remittenceOptionChangeEvent (event) {
            let gatewayOption = this.getRemittanceOptions2.filter(s => (s.Currency === this.countryCodeList.Currency) && (s.Country === this.countryCodeList.Code) && (s.Category === event))
            this.gatewayList = gatewayOption.map( s=> {
                return {
                    Text:  s.Description ,
                    Value: s.RemittanceOptionId 
                }                
            })
        },
        gatewayChangeEvent (event) {
            this.fillRecipientFields(event)          
        },
        addRecipient(useremail,country) {
            if (useremail) {
                this.useremail = useremail
                this.reserveUseremail(useremail)
                this.recipientFeides = []
                this.remittenceOptionList = []
                this.gatewayList = []
                this.action = 'Add'
                this.showContainer = false
                this.showSubmitButton = false
                this.remittenceOptionId = ''
                this.gatewayId =''
                this.remittanceCurrency = ''
                if(country){
                    this.opCountry  = country
                    this.opCountryChangeEvent(country)
                }else {
                    this.opCountry = ''
                }
            }
            this.editRemitInfoOnAdd = true
        },
        async editRecipientDetails(recipient) {
            this.loaderVisible = true
            this.editRemitInfoOnAdd = false
            this.useremail = this.getUseremail        
            this.recipientId = recipient.Id
            // for getting updated details 
            // await this.fetchRecipientDetails({'CustomerEmail':this.useremail, 'Country': this.country})
            let recipientData =  this.getRecipientDetails.find(s=> s.Id === recipient.Id)
            // this.opCountry =  this.opContryList.find(s=> s.Currency === recipientData.RemittanceCurrency)?.Text
            this.opCountry =  this.opContryList.find(s=> s.Text === recipientData.Country)?.Text
            await this.opCountryChangeEvent(this.opCountry)    
            let remittanceOption = this.getRemittanceOptions2.find(s=> s.RemittanceOptionId === recipientData.RemittanceGatewayId)            
            this.remittenceOptionId = remittanceOption?.Category
            this.remittanceCurrency = remittanceOption?.Currency
            await this.remittenceOptionChangeEvent(this.remittenceOptionId)
            this.gatewayId = recipientData.RemittanceGatewayId
            await this.fillRecipientFields(recipientData.RemittanceGatewayId , recipientData)
            Object.values(recipientData).map((el, key) => {
                const objectKy = Object.keys(recipientData)[key]
                if(objectKy === 'CustomProperties') {
                    if (el) {
                        Object.values(el).map((cusEl, cuskey) => {   
                            if (Array.isArray(cusEl)) {
                                Object.values(cusEl).map((pairEl, pairValue) => {
                                    let temp = this.recipientFeides.find(s=> s.Name === pairEl.Name) 
                                    if(temp) {
                                        temp.DefaultValue = pairEl.Value           
                                    }                            
                                })
                            } else {
                                let temp = this.recipientFeides.find(s => s.Name === cusEl.Name)
                                if(temp) {
                                    temp.DefaultValue = cusEl.Value
                                }
                            }                                   
                        })
                    }
                }
                let temp = this.recipientFeides.find(s=> s.Name === objectKy) 
                if(temp) {
                    temp.DefaultValue = el
                }             
            })
            this.recipientFeides.forEach(function (item, index, array) {
                if(item.Name === "bankIFSCBankCode") {
                    item.DefaultValue = array[index+1].DefaultValue.slice(0,5)
                    item.IFSCNumber = array[index+1].DefaultValue.slice(5)
                }
                if (item.Name.slice(0,3) === 'cfm') {
                    item.DefaultValue = array[index-1].DefaultValue
                }
                if (item.Name === 'MobileNumber') {
                    let dialerCode_length = item.DialerCode.length

                    if (item.DefaultValue && item.DialerCode.slice(1) === item.DefaultValue.slice(0,dialerCode_length-1)) {
                        item.DefaultValue = item.DefaultValue.slice(dialerCode_length)
                    }   
                }
            })
            this.showContainer = false
            this.action = 'Update' 
        },
        async onSubmit($event) {   
            let isValidArray = []   
            let temp
            this.recipientFeides.forEach(s=> {
                if(s.Optional === 'false' && !s.DefaultValue) {
                    isValidArray.push(false)
                }
                if (s.UseRegEx && s.DefaultValue) {
                    isValidArray.push(new RegExp(s.UseRegEx).test(s.DefaultValue))
                }
                if(s.Description === 'Account Number') { temp = s.DefaultValue }
                if(s.Description === 'Confirm Account Number') { 
                    if(s.DefaultValue !== temp) {
                        isValidArray.push(false)
                    }
                }
                if (s.Name === 'bankIFSCCode') {
                    if(s.DefaultValue) {
                        let isValid = (s.DefaultValue.slice(5)).length ===  6 ? true : false
                        isValidArray.push(isValid)
                    }
                }
            })
            if (isValidArray.includes(false)) {
                return   
            }      
            this.$bvModal.msgBoxConfirm('Are you sure you want to update the Recipient ?')
            .then(async value => {
                if (value) {
                     try {           
                        this.loaderVisible = true
                        if (this.getRemittanceOptions2 === null || this.getRemittanceOptions2 == undefined) {
                            await this.fetchRemittanceOption()
                        }
                        let remittenceOption = this.getRemittanceOptions2.find(s=> s.RemittanceOptionId === this.gatewayId)          
                        await this.fetchRecipientDetails({'CustomerEmail':this.useremail})
                        let recipient = {}
                        let currentRecipient = {}              
                        if (remittenceOption != null) {                 
                                this.recipientFeides.map(el => {
                                    if (el.Name.slice(0,3) !== 'cfm') {
                                        currentRecipient[el.Name] = el.DefaultValue
                                    } 
                                    if (el.Name === 'MobileNumber') {
                                        currentRecipient[el.Name] = el.DialerCode.slice(1)+' '+el.DefaultValue
                                    }
                                    if(el.Name === 'bankIFSCCode') {
                                        currentRecipient[el.Name] = currentRecipient['bankIFSCBankCode'] + el.DefaultValue.slice(5)
                                    }       
                                })                           
                                if (!this.checkDuplicateRecipient(this.getRecipientDetails, currentRecipient,remittenceOption, this.countryCodeList.Currency, '0' )) {
                                    let CustomProperties = []
                                    if ( this.countryCodeList.Currency === 'GBP' || this.countryCodeList.Currency === 'USD' || this.countryCodeList.Currency === 'HKD' || this.countryCodeList.Currency === 'SGD' || this.countryCodeList.Currency === 'AUD' ) {
                                        if (currentRecipient?.AccountNumber) {
                                            CustomProperties.push({
                                                'Name' : 'AccountNumber',
                                                'Value': currentRecipient.AccountNumber                                       
                                            })
                                            delete currentRecipient['AccountNumber']
                                        }
                                    }                           
                                    if (this.countryCodeList.Currency === 'INR' || this.countryCodeList.Currency === 'PHP') {
                                        if (currentRecipient?.accountNo) {
                                            CustomProperties.push({
                                                'Name' : 'accountNo',
                                                'Value': currentRecipient.accountNo                                       
                                            })
                                            delete currentRecipient['accountNo']
                                            delete currentRecipient['cfmaccountNo']
                                        }
                                        if (this.countryCodeList.Currency ===  'PHP') {
                                            if (currentRecipient?.bankCode) {
                                                CustomProperties.push({
                                                    'Name' : 'bankCode',
                                                    'Value': currentRecipient?.bankCode
                                                })
                                                delete currentRecipient['bankCode']
                                            }
                                        }
                                        if (this.countryCodeList.Currency ===  'INR') {
                                            if (currentRecipient.Relationship) {
                                                CustomProperties.push({
                                                    'Name' : 'Relationship',
                                                    'Value': currentRecipient?.Relationship
                                                })
                                                delete currentRecipient['Relationship']
                                            }
                                            if (this.gatewayId !== '47') {
                                                if (this.gatewayId === '11') {
                                                    CustomProperties.push({
                                                        'Name' : 'bankIFSCCode',
                                                        'Value': currentRecipient?.bankIFSCCode
                                                    })
                                                    if (currentRecipient.BenDOB) {
                                                        CustomProperties.push({
                                                            'Name': 'BenDOB',
                                                            'Value': currentRecipient.BenDOB
                                                        })
                                                    }
                                                    delete currentRecipient['BenDOB']
                                                    delete currentRecipient['bankIFSCCode']
                                                    delete currentRecipient['bankIFSCBankCode']
                                                }
                                                if (this.gatewayId === '60') {
                                                    CustomProperties.push({
                                                        'Name' : 'beneficiaryIFSC',
                                                        'Value': currentRecipient?.beneficiaryIFSC
                                                    })
                                                    delete currentRecipient['beneficiaryIFSC']
                                                }
                                                if (currentRecipient?.beneficiaryAccountNo) {
                                                    CustomProperties.push({
                                                        'Name' : 'beneficiaryAccountNo',
                                                        'Value': currentRecipient?.beneficiaryAccountNo
                                                    })
                                                    delete currentRecipient['beneficiaryAccountNo']
                                                }
                                            }
                                        }                   
                                    }
                                    if (this.countryCodeList.Currency == 'EUR' || this.countryCodeList.Currency == 'HKD' || this.countryCodeList.Currency == 'MXN' || this.countryCodeList.Currency == 'SGD' || this.countryCodeList.Currency == 'AUD' || this.countryCodeList.Currency == 'CZK' || this.countryCodeList.Currency == 'HUF' || this.countryCodeList.Currency == 'PLN' || this.countryCodeList.Currency == 'DKK' || this.countryCodeList.Currency == 'SEK' || this.countryCodeList.Currency == 'NOK') {
                                        CustomProperties.push({
                                            'Name' : 'BicSwift',
                                            'Value': currentRecipient.BicSwift                                       
                                        })
                                        delete currentRecipient['BicSwift']                               
                                    }
                                    if (this.countryCodeList.Currency == 'EUR' || this.countryCodeList.Currency == 'CZK' || this.countryCodeList.Currency == 'HUF' || this.countryCodeList.Currency == 'PLN' || this.countryCodeList.Currency == 'DKK' || this.countryCodeList.Currency == 'SEK' || this.countryCodeList.Currency == 'NOK' || this.countryCodeList.Currency == 'INR') {
                                        if (this.gatewayId === '47') {
                                            CustomProperties.push({
                                                'Name' : 'IBAN',
                                                'Value': currentRecipient.Iban                                       
                                            })
                                            delete currentRecipient['Iban']
                                        } else if (this.gatewayId === '60') {
                                            CustomProperties.push({
                                                'Name' : 'Iban',
                                                'Value': currentRecipient.Iban                                       
                                            })
                                            delete currentRecipient['Iban']                                   
                                        }                             
                                    }
                                    if (this.countryCodeList.Currency === 'GBP' || this.countryCodeList.Currency === 'USD' || this.countryCodeList.Currency === 'AUD' || this.countryCodeList.Currency === 'MXN') {
                                        CustomProperties.push({
                                            'Name' : 'RoutingCodeValue1',
                                            'Value': currentRecipient.RoutingCodeValue1
                                        })
                                        delete currentRecipient['RoutingCodeValue1']                    
                                    }
                                    if (this.countryCodeList.Currency === 'MXN' || this.countryCodeList.Currency === 'CZK' || this.countryCodeList.Currency === 'HUF' || this.countryCodeList.Currency === 'AUD') {
                                        CustomProperties.push({
                                            'Name' : 'BeneficiaryPostcode',
                                            'Value': currentRecipient.BeneficiaryPostcode
                                        })
                                        delete currentRecipient['BeneficiaryPostcode']                    
                                    }
                                    if (this.countryCodeList.Currency === 'MXN') {
                                        CustomProperties.push({
                                            'Name' : 'BankName',
                                            'Value': currentRecipient.BankName
                                        })
                                        delete currentRecipient['BankName']                    
                                        CustomProperties.push({
                                            'Name' : 'BeneficiaryIdentificationValue',
                                            'Value': currentRecipient.BeneficiaryIdentificationValue
                                        })
                                        delete currentRecipient['BeneficiaryIdentificationValue'] 
                                    }
                                    if (this.countryCodeList.Currency === 'CNY') {
                                        if (currentRecipient?.bAccNum) {
                                            CustomProperties.push({
                                                'Name' : 'bAccNum',
                                                'Value': currentRecipient?.bAccNum
                                            })
                                            delete currentRecipient['bAccNum']
                                        }    
                                        if (currentRecipient?.bIdType) {
                                            CustomProperties.push({
                                                'Name' : 'bIdType',
                                                'Value': currentRecipient?.bIdType
                                            })
                                            delete currentRecipient['bIdType']
                                        }
                                        if (currentRecipient?.bIdNum) {
                                            CustomProperties.push({
                                                'Name' : 'bIdNum',
                                                'Value': currentRecipient?.bIdNum
                                            })
                                            delete currentRecipient['bIdNum']
                                        }
                                        if (currentRecipient?.bAccType) {
                                            CustomProperties.push({
                                                'Name' : 'bAccType',
                                                'Value': currentRecipient?.bAccType
                                            })
                                            delete currentRecipient['bAccType']
                                        }
                                        if (currentRecipient?.bIssuerCode) {
                                            CustomProperties.push({
                                                'Name' : 'bIssuerCode',
                                                'Value': currentRecipient?.bIssuerCode
                                            })
                                            delete currentRecipient['bIssuerCode']
                                        }                            
                                    }
                                    if (this.countryCodeList.Currency === 'LKR') {

                                        CustomProperties.push({
                                            'Name' : 'BeneficiaryIDType',
                                            'Value': currentRecipient.BeneficiaryIDType
                                        })
                                        delete currentRecipient['BeneficiaryIDType']
                                        CustomProperties.push({
                                            'Name' : 'BeneficiaryIDNumber',
                                            'Value': currentRecipient.BeneficiaryIDNumber
                                        })
                                        delete currentRecipient['BeneficiaryIDNumber']         
                                        if (this.gatewayId === '40' || this.gatewayId === '41') {
                                            CustomProperties.push({
                                                'Name' : 'BeneficiaryBranchCode',
                                                'Value': currentRecipient.BeneficiaryBranchCode
                                            })
                                            delete currentRecipient['BeneficiaryBranchCode']
                                            CustomProperties.push({
                                                'Name' : 'BeneficiaryBankAccount',
                                                'Value': currentRecipient.BeneficiaryBankAccount
                                            })
                                            delete currentRecipient['BeneficiaryBankAccount']
                                        }
                                        if (this.gatewayId === '41') {
                                            // CustomProperties.push({
                                            //     'Name' : 'BeneficiaryBranchCode',
                                            //     'Value': currentRecipient.BeneficiaryBranchCode
                                            // })
                                            // delete currentRecipient['BeneficiaryBranchCode']
                                            CustomProperties.push({
                                                'Name' : 'BeneficiaryBankCode',
                                                'Value': currentRecipient.BeneficiaryBankCode
                                            })
                                            delete currentRecipient['BeneficiaryBankCode']
                                        } 
                                    }
                                    if (this.countryCodeList.Currency === 'PKR') {
                                        if (this.gatewayId === '44' || this.gatewayId === '45') {
                                            CustomProperties.push({
                                                'Name' : 'AccountNo',
                                                'Value': currentRecipient.AccountNo
                                            })
                                            delete currentRecipient['AccountNo']
                                            delete currentRecipient['cfmaccountNo']
                                        }
                                        if (this.gatewayId === '45') {
                                            CustomProperties.push({
                                                'Name' : 'BranchCode',
                                                'Value': currentRecipient.BranchCode
                                            })
                                            delete currentRecipient['BranchCode']
                                            CustomProperties.push({
                                                'Name' : 'BankCode',
                                                'Value': currentRecipient.BankCode
                                            })
                                            delete currentRecipient['BankCode']
                                            CustomProperties.push({
                                                'Name' : 'BranchName',
                                                'Value': currentRecipient.BranchName
                                            })
                                            delete currentRecipient['BranchName']
                                        }
                                        CustomProperties.push({
                                                'Name' : 'Remarks',
                                                'Value': currentRecipient.Remarks
                                            })
                                        delete currentRecipient['Remarks']
                                    }
                                    if (this.countryCodeList.Currency === 'INR') {
                                        if (this.gatewayId === '47') {
                                            if (currentRecipient?.BankAccount) {
                                                CustomProperties.push({
                                                    'Name' : 'BankAccount',
                                                    'Value': currentRecipient.BankAccount
                                                })
                                            }
                                            if (currentRecipient?.BankAccountType) {
                                                CustomProperties.push({
                                                    'Name' : 'BankAccountType',
                                                    'Value': currentRecipient.BankAccountType
                                                })
                                            }
                                        }
                                        if (this.gatewayId === '60' ) {
                                            CustomProperties.push({
                                                'Name' : 'idType',
                                                'Value': currentRecipient.idType
                                            })
                                            delete currentRecipient['idType']
                                            CustomProperties.push({
                                                'Name' : 'idNumber',
                                                'Value': currentRecipient.idNumber
                                            })
                                            delete currentRecipient['idNumber']
                                            CustomProperties.push({
                                                'Name' : 'issueDate',
                                                'Value': currentRecipient.issueDate
                                            })
                                            delete currentRecipient['issueDate']
                                            CustomProperties.push({
                                                'Name' : 'expiryDate',
                                                'Value': currentRecipient.expiryDate
                                            })
                                            delete currentRecipient['expiryDate']
                                        }                             
                                    }
                                    if (this.gatewayId === '11' || this.gatewayId === '43' || this.gatewayId === '44' || this.gatewayId === '45' || this.gatewayId === '48' || this.gatewayId === '49' || this.gatewayId === '60' || this.gatewayId === '61') {                             
                                        CustomProperties.push({
                                            'Name' : 'TransactionPurposeId',
                                            'Value': currentRecipient.TransactionPurposeId
                                        })
                                        delete currentRecipient['TransactionPurposeId']
                                    }
                                    currentRecipient['CustomProperties'] = CustomProperties
                                    currentRecipient['RemittanceCurrency'] = this.remittanceCurrency
                                    currentRecipient['RemittanceGatewayId'] = this.gatewayId
                                    let operationType = ''
                                    if(this.action === 'Add') {
                                        operationType = 'A'
                                    } else {
                                        currentRecipient['Id'] = this.recipientId
                                        operationType = 'U'
                                    }
                                    // for adding lastname 
                                    if (!currentRecipient['LastName'] && (this.gatewayId === '41' || this.gatewayId === '42') ) {
                                        currentRecipient['LastName'] = ''
                                    }
                                    await AccountService.methods.updateRecipientDetails({
                                        'OperationType' : operationType,
                                        'CustomerEmail' : this.getUseremail,
                                        'Recipient'     : currentRecipient
                                    })
                                    .then(res => {
                                        let msg = ''
                                        if (this.action === 'Add') {
                                            msg = 'Recipient Added Successfully'
                                            this.$refs.childRecipient.getRecipients()
                                        } else {
                                            msg = 'Recipient Updated Successfully'
                                        }
                                        this.showAlert(1, msg)
                                        setTimeout( () =>{ this.GoBack()},3000)                            
                                    })
                                    .catch( err => {
                                        this.alertObject = err
                                        this.$bvModal.show(this.$refs.childModal.id)
                                    })
                                    this.loaderVisible = false 
                                    return false
                                } else {
                                    this.showAlert(null, 'Error while adding recipient : Recipient Already Present.')
                                }
                            return false
                            } else {                 
                            this.showAlert(null, 'Error while managing recipient detail.')
                        }                         
                    } catch (error) {
                        this.loaderVisible = false  
                        this.alertObject = error
                        this.$bvModal.show(this.$refs.childModal.id)
                    }
                }               
            })
            .catch(err => {                
            })             
         
           return false
        },
        checkDuplicateRecipient(recipientList, recipient ,remittencOption, Currency, status, recipientId = null)  {           
            if (status != '0') { 
                recipientList = recipientList.find(s=> s.Id === recipientId) 
            }            
            if (recipientList.length > 0) {
                let isRecipientPresent = recipientList.find( s=> s.FirstName?.toString().toLowerCase() === recipient.FirstName?.toString().toLowerCase() && s.LastName?.toString().toLowerCase() === recipient.LastName?.toString().toLowerCase() && s.RemittanceGatewayId === this.gatewayId )
                if (isRecipientPresent?.length > 0) {
                    return true
                } else {
                    return false
                }
            }

            return false
        },
        GoBack() {
            this.showContainer = true
            this.showSubmitButton = false
        },
        async fillRecipientFields (event, recipientData = null) {
            this.showSubmitButton = true
            this.gatewayId = event
            this.remittanceCurrency = (this.getRemittanceOptions2.find(s=> s.RemittanceOptionId === event)).Currency 
            this.recipientFefillRecipientFieldsides = null
            this.loaderVisible = true
            await AccountService.methods.getRecipientFields(event).
            then ( res => {
                this.recipientFeides = res.GetRecipientFieldsResponse.FieldData.DetailedFieldData                    
                for (const detailedData in this.recipientFeides) {                   
                    for (const item in this.recipientFeides[detailedData]){                        
                        if ({}.hasOwnProperty.call(this.recipientFeides[detailedData][item],'ValueDescription')) {
                            for (const valueDescription in this.recipientFeides[detailedData][item]) {
                                for (const value in this.recipientFeides[detailedData][item][valueDescription]) {
                                    for (const description in this.recipientFeides[detailedData][item][valueDescription][value]) {
                                        this.recipientFeides[detailedData][item][valueDescription][value][description] = this.recipientFeides[detailedData][item][valueDescription][value][description]._text
                                    }                 
                                }
                            }
                        } else {
                            this.recipientFeides[detailedData][item] = this.recipientFeides[detailedData][item]._text                            
                        }
                    }
                }                
            })
            if (!this.recipientFeides.find(s => s.Name === 'Country') && this.recipientFeides.length > 0) {
                this.recipientFeides.push({
                    'DefaultValue'         : this.opCountry,
                    'Description'          : 'Country' ,
                    'MaxLength'            : 100,
                    'MinLength'            : 0,
                    'Name'                 : 'Country',
                    'Optional'             : false,
                    'Order'                : 99,
                    'PossibleValues'       : null,
                    'RequiresConfirmation' : false,
                    'Row'                  : null,
                    'UseRegEx'             : null,
                })
            } else {
                this.recipientFeides.find(s =>s.Name === 'Country').DefaultValue = this.opCountry
            }
            if (this.getRemittanceOptions2 == null) {
                this.fetchRemittanceOption()
            }
            let country = this.countryList.find(s=> s.Text === this.opCountry)
            let remittanceOption = this.getRemittanceOptions2.find(s=> s.RemittanceOptionId === event)
            if (this.recipientFeides.find(s => (s.Name === 'bankIFSCCode' || s.Name === 'beneficiaryIFSC') && s.Name !== 'bankIFSCBankCode')) {
                let possibleValue = null
                let tempPossibleValue = null
                await AccountService.methods.getBankList(remittanceOption.RemittanceOptionId)
                    .then (res => {
                        tempPossibleValue = res.GetBankListResponse.BankData.BankDataItem
                        tempPossibleValue = tempPossibleValue ? (Array.isArray(tempPossibleValue) ? tempPossibleValue : [tempPossibleValue]) : []
                        tempPossibleValue.forEach(bankObject => Object.keys(bankObject).map(key => bankObject[key] = bankObject[key]._text ? bankObject[key]._text : null))
                        tempPossibleValue = tempPossibleValue.filter( s=> s.BankCode != null && s.AccountNumberMaxLength != null && s.AccountNumberMinLength != null)                       
                    })
                
                
                if (remittanceOption.RemittanceOptionId == '2' || remittanceOption.RemittanceOptionId == '11' || remittanceOption.RemittanceOptionId == '41' || remittanceOption.RemittanceOptionId == '45' || remittanceOption.RemittanceOptionId == '60') {
                    possibleValue =  tempPossibleValue.map ( el => {
                        let len =el.BankCode.length
                        for (let index = 5; index > len; index--) {
                            el.BankCode += "0"                           
                        }
                        return {
                            // Description : el.BankName + " - " + el.AccountNumberLength, 
                            Description : el.BankName,                                                               
                            Value: el.BankCode
                        }
                    })
                    
                } else {
                    possibleValue =  tempPossibleValue.map ( el => {                       
                        return {
                            // Description : el.BankName + " - " + el.AccountNumberLength, 
                              Description : el.BankName,                                                             
                            Value: el.BankCode
                        }
                    })                                                            
                }            
                this.recipientFeides.push({
                    'DefaultValue'         : null,
                    'Description'          : 'Bank' ,
                    'MaxLength'            : 100,
                    'MinLength'            : 0,
                    'Name'                 : 'bankIFSCBankCode',
                    'Optional'             : false,
                    'Order'                : 10,
                    'PossibleValues'       :  { "ValueDescription" : possibleValue },
                    'RequiresConfirmation' : false,
                    'Row'                  : null,
                    'UseRegEx'             : null,
                })                                
            }
            this.recipientFeides.map( s=> {

                if (s.RequiresConfirmation === 'true') {
                    this.recipientFeides.push({
                    'DefaultValue'         : s.DefaultValue,
                    'Description'          : 'Confirm ' + s.Description ,
                    'MaxLength'            : s.MaxLength,
                    'MinLength'            : s.MinLength,
                    'Name'                 : 'cfm'+s.Name,
                    'Optional'             : s.Optional,
                    'Order'                : s.Order,
                    'PossibleValues'       : s.PossibleValues,
                    'RequiresConfirmation' : false,
                    'Row'                  : s.Row,
                    'UseRegEx'             : s.UseRegEx,                        
                    })                                        
                }
                // if ((s.Name === 'bankIFSCCode' || s.Name ==='beneficiaryIFSC') && (s.PossibleValues !== null &&  s.PossibleValues > 0)) {
                //     s.Description = '"Branch Code'                    
                // }         
            }) 
            
            this.recipientFeides.sort(function (a, b) {
                return a.Order - b.Order
            }) 
            this.recipientFeides.forEach(item => {
                if(item.Name === 'bankIFSCCode' || item.Name === 'beneficiaryIFSC') {
                    item.MaxLength = "6"
                    item.MinLength = "6"
                }
                if (item.Name === 'MobileNumber') {
                    item.DefaultValue = ''
                    item.DialerCode = this.countryCodeList.DialerCode // for adding Dialer code in Mobile number field
                     if (item.MinLength === item.MaxLength ) {
                        item.MinLength = item.MinLength - item.DialerCode.length
                        item.MaxLength = item.MaxLength - item.DialerCode.length
                    }
                }
            })          
            this.loaderVisible = false
        },
        changeIFSC (value) {
            try {
                this.recipientFeides.forEach(function (item, index, array) {
                    if(item.Name === 'bankIFSCCode' || item.Name === 'beneficiaryIFSC') {
                        if (array[index-1].DefaultValue) {
                            item.DefaultValue = array[index-1].DefaultValue.slice(0,5) + value 
                        } 
                    }
                })
            } catch (error) {
                //
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        validate (field) {
            if (field.Name === 'BenDOB') {
                let regex = new RegExp(field.UseRegEx)
                return  regex.test(field.DefaultValue)
            } else return true
        }   
    },
    computed : {
        ...mapGetters(['getRecipientDetails','getRemittanceOptions2','getUseremail']),
        recipientView () {
            return this.action === 'Add' ? true : false 
        },
        editRemitInfo () {
            let result = Utils.methods.checkUserACL('AllowEditRemittance')
            if (result) {
                result = false
            }else if (!result && this.editRemitInfoOnAdd) {
                result = false
            } else {
                result = true
            }
            return result
        }
    },
    components : {
        Loading, getRecipient, modal
    }
}
</script>